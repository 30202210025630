<template>
	<div v-if="block.button_modal_enabled">
		<div class="row justify-content-start mb-4 mt-3">
			<div
				class="col-12 justify-content-start align-items-start d-flex flex-column mt-4 mb-4"
				v-html="block.button_modal_text"
			/>
			<div class="col-12 d-flex justify-content-start align-items-start mb-4">
				<b-button
					:variant="isAlternative ? 'outline-secondary' : 'primary'"
					class="mb-3 mr-lg-3"
					@click="handleOkPressed"
				>
					ДА, у нас 10 или более участников
				</b-button>
				<b-button
					:variant="isAlternative ? 'primary' : 'outline-secondary'"
					class="mb-3"
					@click="handleNoPressed"
				>
					НЕТ, я частное лицо или наc менее 10 участников
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChangeDynamicButtonSettingsModal',
	props: {
		block: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isAlternative() {
			return this.$attrs.value?.alternative;
		},
	},
	methods: {
		handleNoPressed() {
			const settings = {
				alternative: true,
			};
			this.$cookies.set(`block-${this.block.id}-dynamic-buttons-settings`, settings);

			this.$emit('input', settings);
		},
		handleOkPressed() {
			const settings = {
				alternative: false,
			};
			this.$cookies.set(`block-${this.block.id}-dynamic-buttons-settings`, settings);

			this.$emit('input', settings);
		},
	},
};
</script>

<style lang="scss">
.corporate-modal {
	border-top: 0px solid transparent !important;
}
</style>
