import { render, staticRenderFns } from "./ChangeDynamicButtonSettingsModal.vue?vue&type=template&id=4d8a2766"
import script from "./ChangeDynamicButtonSettingsModal.vue?vue&type=script&lang=js"
export * from "./ChangeDynamicButtonSettingsModal.vue?vue&type=script&lang=js"
import style0 from "./ChangeDynamicButtonSettingsModal.vue?vue&type=style&index=0&id=4d8a2766&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports