<template>
	<div
		:class="['panel', 'programms',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div v-if="isHeaderVisible" class="panel_header">
				<div class="row align-items-end">
					<div class="col-12">
						<h2>{{ block.block_title }}</h2>
					</div>
				</div>
			</div>

			<change-dynamic-button-settings-modal v-model="customDynamicButtonSettings" :block="block" />

			<ul class="programms_list row">
				<li v-for="(item, index) in block.items" :key="index" class="col-12 col-md-6">
					<div
						:class="[ 'programm',
							item.appearance? item.appearance : ''
						]"
					>
						<div class="mb-4">
							<b-embed
								v-if="item.embed"
								:src="item.embed + '?rel=0&enablejsapi=1'"
								type="iframe"
								:aspect="item.is_slim? '21by8 ' : '16by9'"
								allowfullscreen
							/>

							<!--!!! Если slim вариант,-->
							<!--то меняем классы embed-responsive-16by9 / embed-responsive-21by8-->
							<!--то же самое в aspect видео выше-->
							<div
								v-if="item.image.src"
								:class="[ 'embed-responsive',
									item.is_slim? 'embed-responsive-21by8 ' : 'embed-responsive-16by9'
								]"
							>
								<img :src="item.image.src" class="embed-responsive-item" alt="">
							</div>
						</div>

						<div class="programm_content">
							<div class="h-100 d-flex justify-content-start flex-column">
								<div v-if="item.tag" class="programm_header">
									<span class="programm_type">{{ item.tag }}</span>
								</div>
								<a v-if="item.url" :href="item.url" class="title">{{ item.title }}</a>
								<div v-else :href="item.url" class="title">
									{{ item.title }}
								</div>
								<div v-if="item.text_4" class="description" v-html="item.text_4" />
								<div v-if="statusB2BAlumni && item.text_b2b_alumni" class="description" v-html="item.text_b2b_alumni" />
							</div>
							<ul class="programm_properties column-count-2 mt-3">
								<li v-if="item.text_1" class="programm_language">
									{{ item.text_1 }}
								</li>
								<li v-if="item.text_2" class="programm_system">
									{{ item.text_2 }}
								</li>
								<li v-if="item.text_3" class="programm_age">
									{{ item.text_3 }}
								</li>
								<li v-if="item.characteristic_4" class="programm_diploma">
									{{ item.characteristic_4 }}
								</li>
								<li v-if="item.characteristic_5" class="programm_price">
									{{ item.characteristic_5 }}
								</li>

								<li v-if="item.characteristic_6" class="programm_place">
									{{ item.characteristic_6 }}
								</li>

								<li v-if="item.characteristic_7" class="programm_tags">
									{{ item.characteristic_7 }}
								</li>

								<li v-if="item.characteristic_8" class="programm_info">
									{{ item.characteristic_8 }}
								</li>
							</ul>
							<div v-if="item.url || item.button" class="programm_content_footer">
								<dynamic-button
									v-if="item.button && !item.button.button_alternative_scenario"
									:block-id="block.id"
									:button="item.button"
								/>
								<dynamic-button
									v-else-if="item.button"
									:block-id="block.id"
									:button="item.button"
									:alternative="customDynamicButtonSettings.alternative"
								/>
								<a
									v-else
									:href="item.url"
									target="_blank"
									class="more_link"
								>
									{{ $t('more') }}
								</a>
								<a
									v-if="item.presentation_link"
									class="btn btn-secondary"
									:href="item.presentation_link"
								>
									Скачать презентацию
								</a>

								<div v-if="item.date" class="programm_content_footer_reminder">
									{{ item.date }}
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>

			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12 col-md-4 col-lg-2" />
					<div class="col-12 col-md-8 col-lg-6 offset-lg-4">
						<div class="panel_footer_info">
							<div class="title">
								Не увидели подходящую программу?
							</div>
							<p class="description">
								Подберите программу самостоятельно или свяжитесь с нашим консультантом,
								чтобы получить всю информацию по программам школы СКОЛКОВО
							</p>
							<div class="btn-panel">
								<a
									id="static_jivosite_top"
									onclick="return jivo_api.open(),!1"
									href="javascript:void(0)"
									class="btn btn-secondary-outer"
									role="button"
								>Чат с консультантом</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import panel from '~/mixins/panel';
import DynamicButton from '~/components/panels/system/DynamicButton.vue';
import ChangeDynamicButtonSettingsModal from '~/components/panels/partials/ChangeDynamicButtonSettingsModal.vue';

export default {
	name: 'PanelProgramms',
	components: {
		ChangeDynamicButtonSettingsModal,
		Swiper,
		SwiperSlide,
		DynamicButton,
	},
	directives: {
		swiper: directive,
	},
	mixins: [panel],
	data() {
		return {
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				loop: false,
			},
			customDynamicButtonSettings: {
				alternative: true,
			},
		};
	},
	computed: {
		statusB2BAlumni() {
			return this.$auth?.user?.alumni_b2b === true && this.$auth.loggedIn;
		},
	},
	mounted() {
		if (this.$cookies.get(`block-${this.block.id}-dynamic-buttons-settings`)) {
			this.customDynamicButtonSettings = this.$cookies.get(`block-${this.block.id}-dynamic-buttons-settings`);
		}
	},
};

</script>
<style lang="scss" scoped>
	@import "/assets/styles/components/home/panel-programms";

	.column-count-2 {
		column-count: 2;
	}
</style>
